import React from 'react';
// import { Counter } from './features/counter/Counter';
import { Calendar } from './components/Calendar'

function App() {

  return (
    <div className="App">
      <Calendar />
    </div>
  );
}

export default App;
